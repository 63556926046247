* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background: #f3f3f3;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.App {
  margin: 0 auto;
  font-family: sans-serif;
  text-align: center;
  padding: 70px 50px;
  /* width: 1120px; */
  align-items: center;
  justify-items: center;
  justify-content: center;
  max-width: 1100px;
}

nav {
  /* line-height: 24px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.menu {
  right: 0;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}

.logo-image {
  left: 0%;
  /* right: 93.98%; */
}

a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #181717;
}

.hero {
  padding-top: 20px;
  display: grid;
  /* grid-template-columns: repeat(auto, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(auto, 1fr)); */
  gap: 32px 32px;
  grid-auto-flow: row;
  /* grid-template-columns: auto auto auto; */
}

section {
  margin: 140px 0 80px 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 32px 32px;
  grid-auto-flow: den se;
}

.caveat,
.hero > h1,
h3 {
  grid-column: 9 / span 1;
  color: #181717;
  text-align: left;
  font-size: 60px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: -0.3px;
}

.caveat,
.hero > h1 {
  font-family: Caveat, cursive;
  grid-column: 1 / span 5;
}

.hero > h3 {
  padding-top: 120px;
}

.hero > h2 {
  grid-column: 6 / span 12;
  grid-area: auto / 6 / auto / 12;
  /* color: #575252; */
  text-align: left;
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 450;
  line-height: 1.2;
  letter-spacing: -0.3px;
  margin: 100px 0 0 0;
  /* min-width: 500; */
}

.experience {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
}

.exp-rows {
  padding: 0px 0px 0px 100px;
}

.timeline {
  grid-column: 2 / span 3;
  text-align: left;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.3px;
}

.description {
  grid-column: 7 / span 6;
  text-align: left;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.3px;
}

.employer {
  text-decoration: underline;
  padding-bottom: 10px;
}

.employericon {
  grid-column: 6 / span 1;
  text-align: right;
  justify-items: center;
}

.btcusd {
  grid-column: 1 / span 1;
  font-size: 14px;
  font-family: monospace;
  font-style: italic;
  text-align: center;
  margin: auto;
  color: rgb(147, 145, 150);
}
