* {
  box-sizing: border-box;
  margin: 0;
}

.career {
  background: #f3f3f3;
  margin: 0 auto;
  font-family: sans-serif;
  text-align: center;
  padding: 70px 50px;
  max-width: 1100px;
  align-items: center;

  h1 {
    font-size: 30px;
    font-weight: 500;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .jobs {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    flex-wrap: row;
    width: 700px;
    padding-top: 50px;
  }
  .job {
    h3 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.3px;
    }
    padding: 40px;
    width: 100;
    background-color: #d3dbd7;
    border-radius: 25px;
  }
}
